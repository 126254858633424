import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

// Senior Healthcare Chart
class LineChart {
  constructor (data, elementID) {
    this.data = data;
    this.elementID = elementID;
  }

  drawChart (data, elementID) {
    var labels = data.map(obj => {return obj.label});
    var values = data.map(obj => {return obj.value});
    var ctx = document.getElementById(elementID).getContext('2d');
    new Chart(ctx, {
      type: 'line',
      plugins: [ChartDataLabels],
      data: {
        labels: labels,
        datasets: [{
          data: values,
          fill: true,
          lineTension: 0.2,
          borderColor: '#0067B1',
          backgroundColor: 'rgba(173, 218, 233, 0.5)',
          pointBackgroundColor: 'white',
          pointBorderColor: '#0067B1',
          pointBorderWidth: 3
        }]
      },
      options:{
        layout: {
          padding: 45
        },
        tooltips:{
          enabled: false
        },
        plugins:{
          crosshair: false,
          datalabels: {
            formatter: function(value, context) {
              var unit = data[context.dataIndex].unit || '';
              var notation = data[context.dataIndex].notation || '';
              var formattedValue = value.toLocaleString('en');
              return `${unit}${formattedValue} ${notation}`;
            },
            backgroundColor: 'rgba(173, 218, 233, 0.5)',
            anchor: 'end',
            align: 'top',
            offset: 10,
            clip: false,
            display: function(context) {
              if (context.chart.width < 280) {
                return context.dataIndex % 2;
              }
                return 'true';
            },
            font: function(context) {
              var width = context.chart.width;
              if (width < 360) {
                return { size: 6 };
              } else if (width < 415){
                return { size: 8 };
              } else if (width < 470 ){
                return { size: 10 };
              } else {
                return { size: 12 };
              }
            }
          }
        },
        responsive: true,
        legend: {
          display: false
        },
        scales: {
          yAxes:[{
            beginAtZero: true,
            gridLines: {
              display: false
            },
            ticks: {
              max: (Math.max(...values) + 20),
              display: false,
              beginAtZero: true,
            }
          }],
          xAxes:[{
            ticks:{
              fontColor: '#0067B1'
            }
          }]
        }
      }
    });
  }

  go () {
    this.drawChart(this.data, this.elementID);
  }
}

export default LineChart;
