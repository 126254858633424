import Chart from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartjsPluginCrosshair from 'chartjs-plugin-crosshair';
import ChartjsPluginDeferred from 'chartjs-plugin-deferred';

import DebtTicker from "./debt_ticker.js";
import BarChartThin from "./bar_chart_thin.js";
import LineChart from "./line_chart.js";
import DebtOverTimeChart from "./debt_over_time_chart.js";
import BarChartThick from "./bar_chart_thick.js";
import BarChartProjected from "./bar_chart_projected.js";

//only enable these plugins on the charts we want them enabled
Chart.plugins.unregister(ChartDataLabels);
Chart.plugins.unregister(ChartjsPluginCrosshair);

Chart.defaults.global.defaultFontFamily =  "trade-gothic-next, sans-serif";
Chart.defaults.global.defaultFontStyle = '700';
Chart.defaults.global.plugins.deferred.enabled = true;
Chart.defaults.global.defaultFontColor = '#0C1315';

window.DebtTicker = DebtTicker;
window.BarChartThin = BarChartThin;
window.LineChart = LineChart;
window.DebtOverTimeChart = DebtOverTimeChart;
window.BarChartThick = BarChartThick;
window.BarChartProjected = BarChartProjected;

