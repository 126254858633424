import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartjsPluginAnnotations from 'chartjs-plugin-annotations'

class BarChartProjected {
  constructor (data, elementID) {
    //reads in an object of data objects keyed by year
    this.data = data;
    //element might not be a canvas
    this.elementID = elementID;
    this.dataValues = Object.values(data)
                            .reduce((acc, element) => {
                               return acc.concat(element.map(
                                 obj => { return obj.value }
                                ))
                              }, [] );
    this.dataMax = Math.max(...this.dataValues);
  }

  go () {
    const that = this;

    //make sure the html is what we expect
    // let oldElement = document.getElementByIds(that.elementID);
    // let newElement = document.createElement('div');
    // newElement.innerHTML = this.carouselString();
    // oldElement.parentNode.replaceChild(newElement, oldElement);

    Object.keys(this.data).forEach( function (dataKey, index) {
      that.drawButtons (dataKey, that.elementID, index);
    });
  }

  drawButtons (dataKey, elementID, index) {
    let outerContainer = document.createElement('div');
    outerContainer.classList.add('carousel-item')
    if (index == 0) {
      outerContainer.classList.add('active')
    }

    let innerContainer = document.getElementById(`${elementID}-indicators`);
    // innerContainer.classList.add('circle-container', 'carousel-item');

    //circles
    let circle = document.createElement('li');
    circle.setAttribute('data-slide-to', index)
    circle.setAttribute('data-target', '#carouselExampleIndicators')
    circle.innerHTML = `<span data='${dataKey}'>${dataKey}</span>`;
    innerContainer.appendChild(circle);
    circle.classList.add('circle');
    circle.setAttribute('data', dataKey);

    if (index == 0) {
      outerContainer.classList.add('active')
      circle.classList.add('active')
    }

    let carousel = document.getElementById(elementID).parentNode;

    circle.addEventListener('click', function (event) {
      $(carousel).carousel(index); 
      $(carousel).carousel();
    })
    let canvas = document.createElement('canvas');
    canvas.setAttribute('id', dataKey);
    outerContainer.appendChild(canvas);

    document.getElementById(elementID).appendChild(outerContainer);

    this.drawChart(this.data[dataKey], canvas);
    
  }

  drawChart (data, canvas) {

    var labels = data.map(obj => { return obj.label });
    var values = data.map(obj => { return obj.value });
    var colors = data.map(obj => { return obj.color });
    var ctxContext = canvas.getContext('2d');

    new Chart(ctxContext, {
      type: 'horizontalBar',
      plugins: [ChartDataLabels],
      data: {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: colors,
        }]
      },
      options:{
        annotation: {
          annotations: [{
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: values[0],
            borderColor: colors[0],
            borderWidth: 1,
            borderDash: [2, 2]
          }]
        },
        layout: {
          padding: {
            right: 70
          }
        },
        tooltips:{
          enabled: false
        },
        plugins: {
          crosshair: false,
          datalabels: {
            formatter: function(value, context) {
              var unit = data[context.dataIndex].unit || '';
              var notation = data[context.dataIndex].notation || '';
              var formattedValue = value.toLocaleString('en');
              return `${unit}${formattedValue} ${notation}`;
            },
            anchor: 'end',
            align: 'left',
            color: 'white',
            font: {
              size: function(context) {
                var width = context.chart.width;
                if (width < 100) {
                  return 10;
                } else {
                  return 18;
                }
              }
            }
          }
        },
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes:[{
            display: false,
            ticks: {
              beginAtZero: true,
              suggestedMax: this.dataMax
            },
          }],
          yAxes:[{
            barPercentage: 1.0,
            categoryPercentage: 0.9,
            gridLines:{
              display: false,
              drawBorder: false
            },
            ticks: {
              fontSize: 17,
              fontColor: Chart.defaults.global.defaultFontColor,
            }
          }]
        }
      }
    });
  }
}

export default BarChartProjected
