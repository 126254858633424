/* global $ */


 // TICKER 

 class DebtTicker {

  constructor(data, tickerTextContainer, publicDebtContainer, tickRate = 1) {
    this.data = data;
    this.$tickerTextContainer = $(tickerTextContainer);
    this.$publicDebtContainer = $(publicDebtContainer);
    this.tickRate = tickRate * 1000;
    this.calcRate = parseFloat(tickRate);
  }
  
  go() {
    this.updateTicker(this.data, this.$tickerTextContainer, 0);
    this.updatePerPerson(this.data, this.$publicDebtContainer);
    // this.lastUpdated(DebtTicker.tickerData.last_updated);
    setInterval(this.updateTicker, this.tickRate, this.data, this.$tickerTextContainer, this.calcRate);
  }

  updateTicker(data, textContainer, calcRate) {
    let debtStart = parseFloat(data["gross_debt"].replace(/,/g, ''));

    let increasePerSecond = parseFloat(data['increase_dollars_per_second']);
    let debtChange = increasePerSecond * calcRate;
    let debtCurrent = textContainer.data('current');
    if (!debtCurrent) {
      debtCurrent = debtStart;
    }
    var newDebt = debtCurrent + debtChange;
    var newDebtString = '$' + Math.round(newDebt).toLocaleString('en');
    textContainer.data('current', newDebt);
    textContainer.html(newDebtString);
  }

  updatePerPerson (data, perPersonContainer) {
    var perPersonString = '$' + data["public_debt"];
    perPersonContainer.html(perPersonString);
  }
}

export default DebtTicker;
