import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

// healthcare costs
class BarChartThin {
  constructor (data, canvasElementId) {
    this.elementID = canvasElementId;
    this.data = data;
  }

  drawChart (data, elementID) {
    var healthcareCtx = document.getElementById(elementID).getContext('2d');
    var healthCareColors = data.map(obj => { return obj.color });
    var healthCareLabels = data.map(obj => { return obj.label });
    var healthCareValues = data.map(obj => { return obj.value });
    new Chart(healthcareCtx, {
      type: 'horizontalBar',
      plugins: [
        ChartDataLabels,
        { beforeDraw: function(c) {
          var width = c.chart.width;
          if (width < 300) {
            c.scales['y-axis-0'].options.ticks.minor.fontSize = 9;
          } else if ( width < 400) {
            c.scales['y-axis-0'].options.ticks.minor.fontSize = 11;
          } else if (width < 620) {
            c.scales['y-axis-0'].options.ticks.minor.fontSize = 15;
          } else {
            c.scales['y-axis-0'].options.ticks.minor.fontSize = 17;
          }
        }
        }
      ],
      data: {
        labels: healthCareLabels,
        datasets: [{
          data: healthCareValues,
          backgroundColor: healthCareColors,
        }]
      },
      options:{
        layout: {
          padding: {
            right: 100,
            left: 45
          }
        },
        tooltips:{
          enabled: false
        },
        plugins: {
          crosshair: false,
          datalabels: {
            formatter: function(value, context) {
              var unit = data[context.dataIndex].unit || '';
              var notation = data[context.dataIndex].notation || '';
              var formattedValue = value.toLocaleString('en');
              return `${unit}${formattedValue} ${notation}`;
            },
            anchor: 'end',
            align: 'right',
            font: function(context) {
              var width = context.chart.width;
              if (width < 300) {
                return {size: 9 };
              } else if ( width < 400) {
                return { size: 11 };
              } else if (width < 620) {
                return { size: 15 };
              } else {
                return { size: 17 };
              }
            }
          },
        },
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes:[{
            display: false,
            stacked: true
          }],
          yAxes:[{
            barPercentage: 0.6,
            gridLines:{
              display: false,
              drawBorder: false
            },
          }]
        }
      }
    });
  }

  go () {
   this.drawChart(this.data, this.elementID)
  }
}

export default BarChartThin;
