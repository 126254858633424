import Chart from 'chart.js'
import ChartjsPluginAnnotations from 'chartjs-plugin-annotations'
import ChartjsPluginCrosshair from 'chartjs-plugin-crosshair'

// debt over time chart
class DebtOverTimeChart {
  constructor (data, elementID) {
    this.data = data;
    this.elementID = elementID;
  }

  go () {
    this.drawChart(this.data, this.elementID);
  }

  drawChart (data, elementID) {
    var labels = Object.keys(data);
    var values = Object.values(data);
    var lastLabel = labels[labels.length - 1];
    var ctx = document.getElementById(elementID).getContext('2d');
    new Chart(ctx, {
      type: 'line',
      plugins: [ChartjsPluginAnnotations, ChartjsPluginCrosshair],
      data: {
        labels: labels,
        datasets: [{
          data: values,
          fill: true,
          borderColor: '#0067B1',
          backgroundColor: '#0067B1',
          pointBorderWidth: 0,
          pointRadius: 0,
        }]
      },
      options:{
        plugins: {
          crosshair: {
            line: {
              color: '#fc9803',  // crosshair line color
              width: 1,           // crosshair line width
            },
            sync: {
              enabled: false,
            },
          }
        },
        tooltips: {
          axis: 'x',
          interpolate: true,
          enabled: true,
          intersect: false,
          callbacks: {
            title: function (a, _d) {
                return a[0].xLabel;
            },
            label: function (i, d) {
                return i.yLabel + '%';
            }
          }
        },
        annotation: {
          annotations: [{
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: '2024', //TODO: this year
            borderColor: '#37B6E3',
            borderDash: [2,2],
            borderWidth: 1,
            label: {
              //same color as senior pop chart
              backgroundColor: '#D7F0F9',
              fontColor: Chart.defaults.global.defaultFontColor,
              fontFamily: Chart.defaults.global.defaultFontFamily,
              // Font size of text, inherits from global
              fontSize: 12,
              fontStyle: "bold",
              xPadding: 8,
              yPadding: 6,
              cornerRadius: 0,
              // Anchor position of label on line, can be one of: top, bottom, left, right, center
              position: "top",
              xAdjust: 34,
              yAdjust: 43,
              // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
              // For horizontal lines positioned left or right, negative values move
              // the label toward the edge, and positive values toward the center.
              enabled: true,
              content: 'ACTUAL'
            }
          },
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: '2024',
            borderColor: 'rgba(0,0,0,0)',
            borderWidth: 0,
            label: {
              //same color as senior pop chart
              backgroundColor: '#D7F0F9',
              fontColor: Chart.defaults.global.defaultFontColor,
              fontFamily: Chart.defaults.global.defaultFontFamily,
              // Font size of text, inherits from global
              fontSize: 9,
              fontStyle: "bold",
              xPadding: 6,
              yPadding: 6,
              cornerRadius: 0,
              // Anchor position of label on line, can be one of: top, bottom, left, right, center
              position: "top",
              xAdjust: 35,
              yAdjust: 66,
              // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
              // For horizontal lines positioned left or right, negative values move
              // the label toward the edge, and positive values toward the center.
              enabled: true,
              content: `${data['2024']}% in 2024`
            }
          },
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value:  lastLabel,
            borderColor: '#37B6E3',
            borderDash: [2,2],
            borderWidth: 1,
            label: {
              //same color as senior pop chart
              backgroundColor: '#D7F0F9',
              fontColor: Chart.defaults.global.defaultFontColor,
              fontFamily: Chart.defaults.global.defaultFontFamily,
              // Font size of text, inherits from global
              fontSize: 12,
              fontStyle: "bold",
              xPadding: 6,
              yPadding: 6,
              cornerRadius: 0,
              // Anchor position of label on line, can be one of: top, bottom, left, right, center
              position: "top",
              xAdjust: 41,
              yAdjust: 3,
              // Adjustment along x-axis (left-right) ofS label relative to above number (can be negative)
              // For horizontal lines positioned left or right, negative values move
              // the label toward the edge, and positive values toward the center.
              enabled: true,
              content: 'PROJECTED'
            }
          },
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: lastLabel,
            borderColor: 'rgba(0,0,0,0)',
            borderWidth: 0,
            label: {
              //same color as senior pop chart
              backgroundColor: '#D7F0F9',
              fontColor: Chart.defaults.global.defaultFontColor,
              fontFamily: Chart.defaults.global.defaultFontFamily,
              // Font size of text, inherits from global
              fontSize: 9,
              fontStyle: "bold",
              xPadding: 6,
              yPadding: 6,
              cornerRadius: 0,
              // Anchor position of label on line, can be one of: top, bottom, left, right, center
              position: "top",
              xAdjust: 50,
              yAdjust: 26,
              // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
              // For horizontal lines positioned left or right, negative values move
              // the label toward the edge, and positive values toward the center.
              enabled: true,
              content: `${data[lastLabel]}% in ${lastLabel}`
            }
          }]
        },
        legend: {
          display: false
        },
        responsive: true,
        scales: {
          yAxes:[{
            gridLines: {
              tickMarkLength: 20
            },
            ticks: {
              suggestedMax: 150,
              stepSize: 30,
              padding: 4,
              beginAtZero: true,
              callback: function(value, _index, _values) {
                if (value == 0) {
                  return '';
                }
                return value + '%';
              }
            }
          }],
          xAxes:[{
            type: 'time',
            ticks: {
              display: true,
            },
            gridLines: {
              tickMarkLength: 15,
              drawOnChartArea: false,
            }
          }]
        },
      }
    });
  }
}

export default DebtOverTimeChart;
